var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { align: "left", justify: "center" } }, [
    _c(
      "div",
      { staticClass: "pa-3", attrs: { align: "center", justify: "center" } },
      [
        _c(
          "v-card",
          { attrs: { align: "left", justify: "left" } },
          [
            _c(
              "v-card-text",
              { staticClass: "pa-3 font-weight-light white black--text" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "grey lighten-4",
                    attrs: { "x-small": "", fab: "", icon: "" }
                  },
                  [
                    _c(
                      "v-icon",
                      { staticClass: "primary--text lighten-2 pa-2" },
                      [_vm._v(" search ")]
                    )
                  ],
                  1
                ),
                _vm._v(" Administração > Pesquisa Avançada ")
              ],
              1
            )
          ],
          1
        ),
        _c(
          "v-card",
          {
            staticClass: "my-2",
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.psequisaAvancadaTitulos.apply(null, arguments)
              }
            }
          },
          [
            _c(
              "v-row",
              { staticClass: "mx-3 mt-3 mb-0", attrs: { align: "center" } },
              [
                _c(
                  "v-col",
                  {
                    staticClass: "d-flex flex-row",
                    attrs: { cols: "12", xs: "12", sm: "12", md: "3", lg: "3" }
                  },
                  [
                    _c("v-textarea", {
                      staticClass: "mt-5",
                      style: {
                        maxHeight: "15rem",
                        overflowY: "auto"
                      },
                      attrs: {
                        "auto-grow": "",
                        solo: "",
                        small: "",
                        rows: "1",
                        label: "Nosso número",
                        hint: "Informe o nosso número",
                        placeholder: "Ex.:477977",
                        "data-cy": "filtroNossoNumero",
                        loading: _vm.loading
                      },
                      model: {
                        value: _vm.filtros.ds_nosso_numero,
                        callback: function($$v) {
                          _vm.$set(_vm.filtros, "ds_nosso_numero", $$v)
                        },
                        expression: "filtros.ds_nosso_numero"
                      }
                    }),
                    _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function({ on, attrs }) {
                              return [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "ma-auto ml-2",
                                    attrs: {
                                      fab: "",
                                      "x-small": "",
                                      elevation: "0"
                                    }
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        _vm._b(
                                          { attrs: { color: "grey" } },
                                          "v-icon",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_vm._v(" mdi-information-outline ")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      },
                      [_c("span", [_vm._v("Informe o Número do Título")])]
                    )
                  ],
                  1
                ),
                _c(
                  "v-col",
                  {
                    staticClass: "d-flex flex-row align-center",
                    attrs: { cols: "12", xs: "12", sm: "12", md: "3", lg: "3" }
                  },
                  [
                    _c("v-text-field", {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: ["###.###.###-##", "XX.XXX.XXX/XXXX-##"],
                          expression: "['###.###.###-##', 'XX.XXX.XXX/XXXX-##']"
                        }
                      ],
                      staticClass: "mt-5",
                      attrs: {
                        solo: "",
                        small: "",
                        label: "CPF/CNPJ",
                        hint: "Informe o Número do CPF/CNPJ",
                        placeholder: "Ex.:000.000.000-00",
                        clearable: "",
                        "data-cy": "filtroCpfCnpj",
                        loading: _vm.loading
                      },
                      model: {
                        value: _vm.filtros.nu_identificacao_devedor,
                        callback: function($$v) {
                          _vm.$set(_vm.filtros, "nu_identificacao_devedor", $$v)
                        },
                        expression: "filtros.nu_identificacao_devedor"
                      }
                    }),
                    _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function({ on, attrs }) {
                              return [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "ma-auto ml-2",
                                    attrs: {
                                      fab: "",
                                      "x-small": "",
                                      elevation: "0"
                                    }
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        _vm._b(
                                          { staticClass: "grey--text" },
                                          "v-icon",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_vm._v(" mdi-information-outline ")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      },
                      [
                        _c("span", [
                          _vm._v(
                            " Informe o CPF/CNPJ, ou apenas seus números iniciais "
                          )
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "v-col",
                  {
                    staticClass: "d-flex flex-row",
                    attrs: { cols: "12", xs: "12", sm: "12", md: "3", lg: "3" }
                  },
                  [
                    _c("v-text-field", {
                      staticClass: "mt-5",
                      attrs: {
                        solo: "",
                        small: "",
                        label: "Nome",
                        hint: "Informe o nome para efetuar a busca",
                        placeholder: "Ex.: José",
                        clearable: "",
                        "data-cy": "nomeDevedor",
                        loading: _vm.loading
                      },
                      model: {
                        value: _vm.filtros.nm_devedor,
                        callback: function($$v) {
                          _vm.$set(_vm.filtros, "nm_devedor", $$v)
                        },
                        expression: "filtros.nm_devedor"
                      }
                    }),
                    _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function({ on, attrs }) {
                              return [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "ma-auto ml-2",
                                    attrs: {
                                      fab: "",
                                      "x-small": "",
                                      elevation: "0"
                                    }
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        _vm._b(
                                          { attrs: { color: "grey" } },
                                          "v-icon",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_vm._v(" mdi-information-outline ")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      },
                      [
                        _c("span", [
                          _vm._v(
                            " Informe o Nome que deseja encontrar, por exemplo: Ricardo "
                          )
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "v-col",
                  {
                    staticClass: "d-flex flex-row",
                    attrs: { cols: "12", xs: "12", sm: "12", md: "3", lg: "3" }
                  },
                  [
                    _c("v-text-field", {
                      staticClass: "mt-5",
                      attrs: {
                        solo: "",
                        small: "",
                        label: "Número do Protocolo",
                        hint:
                          "Informe o número do protocolo para efetuar a busca",
                        clearable: "",
                        "data-cy": "numeroProtocolo",
                        loading: _vm.loading
                      },
                      model: {
                        value: _vm.filtros.apontamento_nu_protocolo,
                        callback: function($$v) {
                          _vm.$set(_vm.filtros, "apontamento_nu_protocolo", $$v)
                        },
                        expression: "filtros.apontamento_nu_protocolo"
                      }
                    }),
                    _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function({ on, attrs }) {
                              return [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "ma-auto ml-2",
                                    attrs: {
                                      fab: "",
                                      "x-small": "",
                                      elevation: "0"
                                    }
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        _vm._b(
                                          { attrs: { color: "grey" } },
                                          "v-icon",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_vm._v(" mdi-information-outline ")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      },
                      [
                        _c("span", [
                          _vm._v(" Informe o protocolo que deseja encontrar ")
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "v-col",
                  {
                    staticClass: "d-flex flex-row",
                    attrs: {
                      cols: "12",
                      xs: "12",
                      sm: "12",
                      md: "3",
                      lg: "3",
                      xl: "3"
                    }
                  },
                  [
                    _c("v-text-field", {
                      staticClass: "mt-5",
                      attrs: {
                        solo: "",
                        small: "",
                        label: "Código Revisão Interna",
                        hint: "Informe o código da Revisão Interna",
                        placeholder: "Ex.: José",
                        clearable: "",
                        "data-cy": "revisaoInterna",
                        loading: _vm.loading,
                        maxlength: "3"
                      },
                      model: {
                        value: _vm.filtros.cd_revisao_interna,
                        callback: function($$v) {
                          _vm.$set(_vm.filtros, "cd_revisao_interna", $$v)
                        },
                        expression: "filtros.cd_revisao_interna"
                      }
                    }),
                    _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function({ on, attrs }) {
                              return [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "ma-auto ml-2",
                                    attrs: {
                                      fab: "",
                                      "x-small": "",
                                      elevation: "0"
                                    }
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        _vm._b(
                                          { attrs: { color: "grey" } },
                                          "v-icon",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_vm._v(" mdi-information-outline ")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      },
                      [
                        _c("span", [
                          _vm._v(
                            " Informe o código da Revisão Interna que deseja filtrar "
                          )
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "v-col",
                  {
                    staticClass: "d-flex flex-row",
                    attrs: {
                      cols: "12",
                      xs: "12",
                      sm: "12",
                      md: "6",
                      lg: "6",
                      xl: "6"
                    }
                  },
                  [
                    _c("v-autocomplete", {
                      staticClass: "mt-5",
                      attrs: {
                        solo: "",
                        small: "",
                        label: "Apresentante",
                        clearable: "",
                        items: _vm.apresentantes,
                        "item-value": "cd_apresentante",
                        "item-text": "nm_apresentante",
                        loading: _vm.loading
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "item",
                          fn: function(data) {
                            return [
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _c(
                                      "span",
                                      {
                                        class: {
                                          "text-gray": !data.item.st_ativo
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(data.item.nm_apresentante)
                                        )
                                      ]
                                    ),
                                    _c("br"),
                                    _c("div", [
                                      _c("small", [_vm._v("Remessa: ")]),
                                      _c(
                                        "small",
                                        {
                                          class: data.item.st_ativo_remessa
                                            ? "text-green"
                                            : "text-error"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              !!data.item.st_ativo_remessa
                                                ? "Ativo"
                                                : "Inativo"
                                            )
                                          )
                                        ]
                                      ),
                                      _c("small", [_vm._v(" | Comando: ")]),
                                      _c(
                                        "small",
                                        {
                                          class: !!data.item.st_ativo_comando
                                            ? "text-green"
                                            : "text-error"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              !!data.item.st_ativo_comando
                                                ? "Ativo"
                                                : "Inativo"
                                            )
                                          )
                                        ]
                                      )
                                    ])
                                  ])
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ]),
                      model: {
                        value: _vm.filtros.cd_apresentante,
                        callback: function($$v) {
                          _vm.$set(_vm.filtros, "cd_apresentante", $$v)
                        },
                        expression: "filtros.cd_apresentante"
                      }
                    }),
                    _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function({ on, attrs }) {
                              return [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "ma-auto ml-2",
                                    attrs: {
                                      fab: "",
                                      "x-small": "",
                                      elevation: "0"
                                    }
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        _vm._b(
                                          { attrs: { color: "grey" } },
                                          "v-icon",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_vm._v(" mdi-information-outline ")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      },
                      [
                        _c("span", [
                          _vm._v(
                            " Informe o apresentante que deseja encontrar - filtro por Razão social "
                          )
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "v-col",
                  {
                    staticClass: "d-flex flex-row",
                    attrs: {
                      cols: "12",
                      xs: "12",
                      sm: "12",
                      md: "3",
                      lg: "3",
                      xl: "3"
                    }
                  },
                  [
                    _c("v-autocomplete", {
                      staticClass: "mt-5",
                      attrs: {
                        solo: "",
                        small: "",
                        label: "Sigla Apresentante",
                        clearable: "",
                        items: _vm.apresentantesOrderByCd,
                        "item-value": "cd_apresentante",
                        "item-text": "cd_apresentante",
                        loading: _vm.loading
                      },
                      model: {
                        value: _vm.filtros.cd_apresentante,
                        callback: function($$v) {
                          _vm.$set(_vm.filtros, "cd_apresentante", $$v)
                        },
                        expression: "filtros.cd_apresentante"
                      }
                    }),
                    _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function({ on, attrs }) {
                              return [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "ma-auto ml-2",
                                    attrs: {
                                      fab: "",
                                      "x-small": "",
                                      elevation: "0"
                                    }
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        _vm._b(
                                          { attrs: { color: "grey" } },
                                          "v-icon",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_vm._v(" mdi-information-outline ")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      },
                      [
                        _c("span", [
                          _vm._v(
                            " Informe o apresentante que deseja encontrar - filtro por sigla "
                          )
                        ])
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-row",
              { staticClass: "mx-0 mt-2 mb-0 pa-0" },
              [
                _c("v-spacer"),
                _c(
                  "v-col",
                  {
                    staticClass: "pr-9",
                    attrs: {
                      cols: "10",
                      xs: "6",
                      sm: "6",
                      md: "3",
                      lg: "3",
                      align: "center"
                    }
                  },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "d-flex justify-center",
                        attrs: {
                          block: "",
                          color: "primary",
                          loading: _vm.loading
                        },
                        on: { click: _vm.psequisaAvancadaTitulos }
                      },
                      [
                        _vm._v(" Filtrar "),
                        _c("v-icon", { staticClass: "ml-2" }, [
                          _vm._v("search")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c("v-data-table", {
          staticClass: "elevation-1 pa-3",
          attrs: {
            headers: _vm.headers,
            items: _vm.titulos,
            "calculate-widths": "",
            options: _vm.options,
            "server-items-length": _vm.totalTitulos,
            loading: _vm.loading,
            "footer-props": {
              itemsPerPageOptions: [10, 50, 100, 500],
              itemsPerPageText: "Itens por página:"
            },
            "loading-text": "Pesquisando títulos...",
            "no-data-text": "Nenhum resultado encontrado... Faça uma pesquisa",
            "no-results-text": "Nenhum título encontrado..."
          },
          on: {
            "update:options": function($event) {
              _vm.options = $event
            }
          },
          scopedSlots: _vm._u(
            [
              {
                key: `item.id_titulo`,
                fn: function({ item }) {
                  return [
                    item.ds_revisao != "" && item.cd_status == 99999
                      ? _c(
                          "span",
                          {
                            style: { color: "red" },
                            attrs: {
                              title: "Este título precisa ser revisado. "
                            }
                          },
                          [_vm._v(" " + _vm._s(item.id_titulo))]
                        )
                      : _c("span", { style: { color: "black" } }, [
                          _vm._v(_vm._s(item.id_titulo))
                        ])
                  ]
                }
              },
              {
                key: `item.ds_nosso_numero`,
                fn: function({ item }) {
                  return [
                    item.ds_revisao != "" && item.cd_status == 99999
                      ? _c(
                          "span",
                          {
                            style: { color: "red" },
                            attrs: {
                              title: "Este título precisa ser revisado. "
                            }
                          },
                          [_vm._v(_vm._s(item.ds_nosso_numero))]
                        )
                      : _c("span", { style: { color: "black" } }, [
                          _vm._v(_vm._s(item.ds_nosso_numero) + " ")
                        ])
                  ]
                }
              },
              {
                key: `item.nu_identificacao_devedor`,
                fn: function({ item }) {
                  return [
                    item.ds_revisao != "" && item.cd_status == 99999
                      ? _c(
                          "span",
                          {
                            style: { color: "red" },
                            attrs: {
                              title: "Este título precisa ser revisado. "
                            }
                          },
                          [_vm._v(_vm._s(_vm.formatDocumento(item)))]
                        )
                      : _c("span", { style: { color: "black" } }, [
                          _vm._v(_vm._s(_vm.formatDocumento(item)))
                        ])
                  ]
                }
              },
              {
                key: `item.nm_devedor`,
                fn: function({ item }) {
                  return [
                    item.ds_revisao != "" && item.cd_status == 99999
                      ? _c(
                          "span",
                          {
                            style: { color: "red" },
                            attrs: {
                              title: "Este título precisa ser revisado. "
                            }
                          },
                          [_vm._v(_vm._s(item.nm_devedor))]
                        )
                      : _c("span", { style: { color: "black" } }, [
                          _vm._v(_vm._s(item.nm_devedor))
                        ])
                  ]
                }
              },
              {
                key: `item.cd_status`,
                fn: function({ item }) {
                  return [
                    item.ds_revisao != "" && item.cd_status == 99999
                      ? _c(
                          "span",
                          {
                            style: { color: "red" },
                            attrs: {
                              title: "Este título precisa ser revisado. "
                            }
                          },
                          [_vm._v(_vm._s(_vm.getDescSituacao(item)))]
                        )
                      : _c("span", { style: { color: "black" } }, [
                          _vm._v(_vm._s(_vm.getDescSituacao(item)))
                        ])
                  ]
                }
              },
              {
                key: `item.cd_uf_devedor`,
                fn: function({ item }) {
                  return [
                    item.ds_revisao != "" && item.cd_status == 99999
                      ? _c(
                          "span",
                          {
                            style: { color: "red" },
                            attrs: {
                              title: "Este título precisa ser revisado. "
                            }
                          },
                          [_vm._v(_vm._s(item.cd_uf_devedor))]
                        )
                      : _c("span", { style: { color: "black" } }, [
                          _vm._v(_vm._s(item.cd_uf_devedor))
                        ])
                  ]
                }
              },
              {
                key: "item.action",
                fn: function(props) {
                  return [
                    _c(
                      "v-btn",
                      {
                        staticClass: "mr-2 grey white--text",
                        style: { color: "primary" },
                        attrs: {
                          elevation: "1",
                          width: "100%",
                          "full-width": "",
                          small: "",
                          "data-cy": `editarTitulo${props.index}`
                        },
                        on: {
                          click: function($event) {
                            return _vm.$router.push({
                              name: "detalhe-titulo",
                              query: {
                                id_titulo: props.item.id_titulo,
                                cd_apresentante: props.item.cd_apresentante
                              }
                            })
                          }
                        }
                      },
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass: "mr-2",
                            style: { color: "white" },
                            on: {
                              click: function($event) {
                                return _vm.$router.push({
                                  name: "detalhe-titulo",
                                  params: {
                                    id_titulo: props.item.id_titulo,
                                    cd_apresentante: props.item.cd_apresentante
                                  }
                                })
                              }
                            }
                          },
                          [_vm._v("edit ")]
                        ),
                        _vm._v(" Detalhes ")
                      ],
                      1
                    )
                  ]
                }
              }
            ],
            null,
            true
          )
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }