<template>
    <div align="left" justify="center" class="">
        <div align="center" justify="center" class="pa-3">
            <v-card align="left" justify="left">
                <v-card-text class="pa-3 font-weight-light white black--text">
                    <v-btn class="grey lighten-4" x-small fab icon
                        ><v-icon class="primary--text lighten-2 pa-2">
                            search
                        </v-icon></v-btn
                    >
                    Administração > Pesquisa Avançada
                </v-card-text>
            </v-card>

            <v-card @keyup.native.enter="psequisaAvancadaTitulos" class="my-2">
                <v-row align="center" class="mx-3 mt-3 mb-0">
                    <v-col
                        cols="12"
                        xs="12"
                        sm="12"
                        md="3"
                        lg="3"
                        class="d-flex flex-row"
                    >
                        <v-textarea
                            auto-grow
                            solo
                            small
                            v-bind:style="{
                                maxHeight: '15rem',
                                overflowY: 'auto'
                            }"
                            rows="1"
                            label="Nosso número"
                            hint="Informe o nosso número"
                            placeholder="Ex.:477977"
                            data-cy="filtroNossoNumero"
                            class="mt-5"
                            v-model="filtros.ds_nosso_numero"
                            :loading="loading"
                        ></v-textarea>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    fab
                                    x-small
                                    class="ma-auto ml-2"
                                    elevation="0"
                                >
                                    <v-icon
                                        color="grey"
                                        v-bind="attrs"
                                        v-on="on"
                                        class=""
                                    >
                                        mdi-information-outline
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>Informe o Número do Título</span>
                        </v-tooltip>
                    </v-col>
                    <v-col
                        cols="12"
                        xs="12"
                        sm="12"
                        md="3"
                        lg="3"
                        class="d-flex flex-row align-center"
                    >
                        <v-text-field
                            solo
                            small
                            class="mt-5"
                            label="CPF/CNPJ"
                            hint="Informe o Número do CPF/CNPJ"
                            placeholder="Ex.:000.000.000-00"
                            v-mask="['###.###.###-##', 'XX.XXX.XXX/XXXX-##']"
                            v-model="filtros.nu_identificacao_devedor"
                            clearable
                            data-cy="filtroCpfCnpj"
                            :loading="loading"
                        >
                        </v-text-field>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    fab
                                    x-small
                                    class="ma-auto ml-2"
                                    elevation="0"
                                >
                                    <v-icon
                                        v-bind="attrs"
                                        v-on="on"
                                        class="grey--text"
                                    >
                                        mdi-information-outline
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>
                                Informe o CPF/CNPJ, ou apenas seus números
                                iniciais
                            </span>
                        </v-tooltip>
                    </v-col>
                    <v-col
                        cols="12"
                        xs="12"
                        sm="12"
                        md="3"
                        lg="3"
                        class="d-flex flex-row"
                    >
                        <v-text-field
                            solo
                            small
                            class="mt-5"
                            label="Nome"
                            hint="Informe o nome para efetuar a busca"
                            placeholder="Ex.: José"
                            clearable
                            v-model="filtros.nm_devedor"
                            data-cy="nomeDevedor"
                            :loading="loading"
                        ></v-text-field>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    fab
                                    x-small
                                    class="ma-auto ml-2"
                                    elevation="0"
                                >
                                    <v-icon
                                        color="grey"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        mdi-information-outline
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>
                                Informe o Nome que deseja encontrar, por
                                exemplo: Ricardo
                            </span>
                        </v-tooltip>
                    </v-col>
                    <v-col
                        cols="12"
                        xs="12"
                        sm="12"
                        md="3"
                        lg="3"
                        class="d-flex flex-row"
                    >
                        <v-text-field
                            solo
                            small
                            class="mt-5"
                            label="Número do Protocolo"
                            hint="Informe o número do protocolo para efetuar a busca"
                            clearable
                            v-model="filtros.apontamento_nu_protocolo"
                            data-cy="numeroProtocolo"
                            :loading="loading"
                        ></v-text-field>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    fab
                                    x-small
                                    class="ma-auto ml-2"
                                    elevation="0"
                                >
                                    <v-icon
                                        color="grey"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        mdi-information-outline
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>
                                Informe o protocolo que deseja encontrar
                            </span>
                        </v-tooltip>
                    </v-col>
                    <v-col
                        cols="12"
                        xs="12"
                        sm="12"
                        md="3"
                        lg="3"
                        xl="3"
                        class="d-flex flex-row"
                    >
                        <v-text-field
                            solo
                            small
                            class="mt-5"
                            label="Código Revisão Interna"
                            hint="Informe o código da Revisão Interna"
                            placeholder="Ex.: José"
                            clearable
                            v-model="filtros.cd_revisao_interna"
                            data-cy="revisaoInterna"
                            :loading="loading"
                            maxlength="3"
                        ></v-text-field>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    fab
                                    x-small
                                    class="ma-auto ml-2"
                                    elevation="0"
                                >
                                    <v-icon
                                        color="grey"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        mdi-information-outline
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>
                                Informe o código da Revisão Interna que deseja
                                filtrar
                            </span>
                        </v-tooltip>
                    </v-col>
                    <v-col
                        cols="12"
                        xs="12"
                        sm="12"
                        md="6"
                        lg="6"
                        xl="6"
                        class="d-flex flex-row"
                    >
                        <v-autocomplete
                            solo
                            small
                            label="Apresentante"
                            class="mt-5"
                            clearable
                            v-model="filtros.cd_apresentante"
                            :items="apresentantes"
                            item-value="cd_apresentante"
                            item-text="nm_apresentante"
                            :loading="loading"
                        >
                            <template v-slot:item="data">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <span
                                            :class="{
                                                'text-gray': !data.item.st_ativo
                                            }"
                                            >{{
                                                data.item.nm_apresentante
                                            }}</span
                                        ><br />
                                        <div>
                                            <small>Remessa: </small>
                                            <small
                                                :class="
                                                    data.item.st_ativo_remessa
                                                        ? 'text-green'
                                                        : 'text-error'
                                                "
                                                >{{
                                                    !!data.item.st_ativo_remessa
                                                        ? 'Ativo'
                                                        : 'Inativo'
                                                }}</small
                                            >
                                            <small> | Comando: </small>
                                            <small
                                                :class="
                                                    !!data.item.st_ativo_comando
                                                        ? 'text-green'
                                                        : 'text-error'
                                                "
                                                >{{
                                                    !!data.item.st_ativo_comando
                                                        ? 'Ativo'
                                                        : 'Inativo'
                                                }}</small
                                            >
                                        </div>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </template>
                        </v-autocomplete>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    fab
                                    x-small
                                    class="ma-auto ml-2"
                                    elevation="0"
                                >
                                    <v-icon
                                        color="grey"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        mdi-information-outline
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>
                                Informe o apresentante que deseja encontrar -
                                filtro por Razão social
                            </span>
                        </v-tooltip>
                    </v-col>
                    <v-col
                        cols="12"
                        xs="12"
                        sm="12"
                        md="3"
                        lg="3"
                        xl="3"
                        class="d-flex flex-row"
                    >
                        <v-autocomplete
                            solo
                            small
                            label="Sigla Apresentante"
                            class="mt-5"
                            clearable
                            v-model="filtros.cd_apresentante"
                            :items="apresentantesOrderByCd"
                            item-value="cd_apresentante"
                            item-text="cd_apresentante"
                            :loading="loading"
                        ></v-autocomplete>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    fab
                                    x-small
                                    class="ma-auto ml-2"
                                    elevation="0"
                                >
                                    <v-icon
                                        color="grey"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        mdi-information-outline
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>
                                Informe o apresentante que deseja encontrar -
                                filtro por sigla
                            </span>
                        </v-tooltip>
                    </v-col>
                </v-row>
                <v-row class="mx-0 mt-2 mb-0 pa-0">
                    <v-spacer></v-spacer>
                    <v-col
                        cols="10"
                        xs="6"
                        sm="6"
                        md="3"
                        lg="3"
                        class="pr-9"
                        align="center"
                    >
                        <v-btn
                            block
                            class="d-flex justify-center"
                            color="primary"
                            :loading="loading"
                            @click="psequisaAvancadaTitulos"
                        >
                            Filtrar
                            <v-icon class="ml-2">search</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card>

            <v-data-table
                :headers="headers"
                :items="titulos"
                calculate-widths
                :options.sync="options"
                :server-items-length="totalTitulos"
                :loading="loading"
                :footer-props="{
                    itemsPerPageOptions: [10, 50, 100, 500],
                    itemsPerPageText: 'Itens por página:'
                }"
                loading-text="Pesquisando títulos..."
                no-data-text="Nenhum resultado encontrado... Faça uma pesquisa"
                no-results-text="Nenhum título encontrado..."
                class="elevation-1 pa-3"
            >
                <template v-slot:[`item.id_titulo`]="{ item }">
                    <span
                        v-if="item.ds_revisao != '' && item.cd_status == 99999"
                        title="Este título precisa ser revisado. "
                        :style="{ color: 'red' }"
                    >
                        {{ item.id_titulo }}</span
                    >
                    <span v-else :style="{ color: 'black' }">{{
                        item.id_titulo
                    }}</span>
                </template>
                <template v-slot:[`item.ds_nosso_numero`]="{ item }">
                    <span
                        v-if="item.ds_revisao != '' && item.cd_status == 99999"
                        title="Este título precisa ser revisado. "
                        :style="{ color: 'red' }"
                        >{{ item.ds_nosso_numero }}</span
                    >
                    <span v-else :style="{ color: 'black' }"
                        >{{ item.ds_nosso_numero }}
                    </span>
                </template>
                <template v-slot:[`item.nu_identificacao_devedor`]="{ item }">
                    <span
                        v-if="item.ds_revisao != '' && item.cd_status == 99999"
                        title="Este título precisa ser revisado. "
                        :style="{ color: 'red' }"
                        >{{ formatDocumento(item) }}</span
                    >
                    <span v-else :style="{ color: 'black' }">{{
                        formatDocumento(item)
                    }}</span>
                </template>
                <template v-slot:[`item.nm_devedor`]="{ item }">
                    <span
                        v-if="item.ds_revisao != '' && item.cd_status == 99999"
                        title="Este título precisa ser revisado. "
                        :style="{ color: 'red' }"
                        >{{ item.nm_devedor }}</span
                    >
                    <span v-else :style="{ color: 'black' }">{{
                        item.nm_devedor
                    }}</span>
                </template>
                <template v-slot:[`item.cd_status`]="{ item }">
                    <span
                        v-if="item.ds_revisao != '' && item.cd_status == 99999"
                        title="Este título precisa ser revisado. "
                        :style="{ color: 'red' }"
                        >{{ getDescSituacao(item) }}</span
                    >
                    <span v-else :style="{ color: 'black' }">{{
                        getDescSituacao(item)
                    }}</span>
                </template>
                <template v-slot:[`item.cd_uf_devedor`]="{ item }">
                    <span
                        v-if="item.ds_revisao != '' && item.cd_status == 99999"
                        title="Este título precisa ser revisado. "
                        :style="{ color: 'red' }"
                        >{{ item.cd_uf_devedor }}</span
                    >
                    <span v-else :style="{ color: 'black' }">{{
                        item.cd_uf_devedor
                    }}</span>
                </template>
                <template slot="item.action" slot-scope="props">
                    <!-- v-if="isVersionBeta" -->
                    <v-btn
                        elevation="1"
                        width="100%"
                        class="mr-2 grey white--text"
                        @click="
                            $router.push({
                                name: 'detalhe-titulo',
                                query: {
                                    id_titulo: props.item.id_titulo,
                                    cd_apresentante: props.item.cd_apresentante
                                }
                            })
                        "
                        full-width
                        small
                        :style="{ color: 'primary' }"
                        :data-cy="`editarTitulo${props.index}`"
                    >
                        <v-icon
                            class="mr-2"
                            @click="
                                $router.push({
                                    name: 'detalhe-titulo',
                                    params: {
                                        id_titulo: props.item.id_titulo,
                                        cd_apresentante:
                                            props.item.cd_apresentante
                                    }
                                })
                            "
                            :style="{ color: 'white' }"
                            >edit
                        </v-icon>
                        Detalhes
                    </v-btn>
                    <!-- <v-btn
                        v-else-if="
                            props.item.ds_revisao != '' &&
                                props.item.cd_status == 99999
                        "
                        title="Este título precisa ser revisado. "
                        elevation="1"
                        class="mr-2"
                        width="100%"
                        @click="exibirDetalhe(props.item)"
                        :style="{ color: 'red' }"
                        :data-cy="`editarTitulo${props.index}`"
                    >
                        <v-icon
                            title="Este título precisa ser revisado. "
                            medium
                            class="mr-2"
                            @click="exibirDetalhe(props.item)"
                            :style="{ color: 'red' }"
                            >edit</v-icon
                        >
                        Revisar
                    </v-btn>
                    <v-btn
                        v-else
                        elevation="1"
                        width="100%"
                        class="mr-2 grey white--text"
                        @click="exibirDetalhe(props.item)"
                        full-width
                        small
                        :style="{ color: 'primary' }"
                        :data-cy="`editarTitulo${props.index}`"
                    >
                        <v-icon
                            class="mr-2"
                            @click="exibirDetalhe(props.item)"
                            :style="{ color: 'white' }"
                            >edit
                        </v-icon>
                        Detalhes
                    </v-btn> -->
                </template>
            </v-data-table>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { getOnlyNumbers } from '@/helpers/Utilitarios';
import * as Formatter from '@/helpers/Formatter';
import ApresentanteService from '../services/apresentanteService';
import TituloService from '../services/TituloService';
import lodash from 'lodash';

export default {
    name: 'PesquisaAvancada',
    components: {},
    data() {
        return {
            apresentanteService: new ApresentanteService(Vue.http, this.$store),
            tituloService: new TituloService(Vue.http, this.$store),
            apresentantes: [],
            apresentantesOrderByCd: [],
            filtros: {},
            loading: false,
            headers: [
                {
                    text: 'ID',
                    sortable: true,
                    value: 'id_titulo'
                },
                {
                    text: 'Número do título',
                    sortable: true,
                    value: 'ds_nosso_numero'
                },
                {
                    text: 'CPF/CNPJ',
                    sortable: true,
                    value: 'nu_identificacao_devedor'
                },
                {
                    text: 'Nome',
                    sortable: true,
                    value: 'nm_devedor'
                },
                {
                    text: 'Apresentante',
                    sortable: true,
                    value: 'cd_apresentante'
                },
                {
                    text: 'Envio',
                    sortable: false,
                    value: 'st_envio'
                },
                {
                    text: 'Status',
                    sortable: false,
                    value: 'cd_status'
                },
                {
                    text: 'UF',
                    sortable: false,
                    value: 'cd_uf_devedor'
                },
                {
                    text: 'Nº Devedores',
                    sortable: false,
                    value: 'nu_quantidade_devedores'
                },
                { text: ' ', sortable: false, value: 'action', width: '15%' }
            ],
            titulos: [],
            options: {
                page: 1,
                itemsPerPage: 10
            },
            totalTitulos: 0
        };
    },
    async mounted() {
        this.listarApresentantes();
    },
    computed: {
        isVersionBeta() {
            return this.$store.getters.isBetaVersion;
        }
    },
    watch: {
        options: {
            handler() {
                if (this.totalTitulos > 0) {
                    this.psequisaAvancadaTitulos();
                }
            },
            deep: true
        }
    },
    methods: {
        getOnlyNumbers,
        listarApresentantes() {
            this.loading = true;
            return new Promise((resolve, reject) => {
                try {
                    this.apresentanteService
                        .listarApresentantes(this.filterSelected)
                        .then(
                            response => {
                                if (response.status == 200) {
                                    let arr_apresentantes = [...response.body];
                                    this.apresentantesOrderByCd = response.body;

                                    arr_apresentantes.sort((a, b) => {
                                        let nomeA = a.nm_apresentante.toUpperCase();
                                        let nomeB = b.nm_apresentante.toUpperCase();
                                        if (nomeA < nomeB) {
                                            return -1;
                                        }
                                        if (nomeA > nomeB) {
                                            return 1;
                                        }
                                        return 0;
                                    });

                                    this.apresentantes = arr_apresentantes;
                                }
                            },
                            error => {
                                console.log(error);
                            }
                        );
                } catch (e) {
                    this.mensagem = e;
                    console.log(e);
                    reject();
                } finally {
                    this.loading = false;
                }
            });
        },
        async psequisaAvancadaTitulos() {
            try {
                this.loading = true;
                const filtro = lodash.cloneDeep(this.filtros);

                if (filtro.nu_identificacao_devedor) {
                    filtro.nu_identificacao_devedor = this.getOnlyNumbers(
                        this.filtros.nu_identificacao_devedor
                    );
                }

                const response = await this.tituloService.pesquisaAvancadaTitulos(
                    filtro,
                    this.options.page,
                    this.options.itemsPerPage
                );

                this.titulos = response.titulos;
                this.totalTitulos = response.total_titulos;
            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false;
            }
        },
        formatDocumento(titulo) {
            return Formatter.formatDocumento(
                titulo.nu_identificacao_devedor,
                titulo.tp_identificacao_devedor
            );
        },
        exibirDetalhe(titulo) {
            this.snackbarLateral = false;
            this.$store.commit('setTitulo', titulo);
            this.ultimoTitulo = titulo;
            this.dialogLoading = true;
            this.somaCounter();
            let tituloService = new TituloService(Vue.http, this.$store);
            tituloService.detalharTitulo(titulo).then(
                response => {
                    const { detalhe_titulo, historico_titulo } = response.body;
                    this.titulo = detalhe_titulo;
                    this.isSaoPaulo =
                        this.titulo.cd_uf_devedor === 'SP' ? true : false;
                    this.historicos = historico_titulo;
                    this.dialog = true;
                    this.dialogLoading = false;
                    this.listarEmailsEnviados();
                    this.verificaStEnvio();
                    if (this.superUsuario === true) {
                        // this.verificarApresentanteProtestado();
                        if (!this.usuarioPartner) {
                            this.listarComentarios(titulo);
                        }
                    }
                },
                error => {
                    this.mensagem = error;
                    this.dialogLoading = false;
                    console.log(error);
                }
            );
        }
    }
};
</script>

<style lang="scss"></style>
